'use client'
import { postLogin } from '@/actions/postlogin'

import { UserRole } from '@/schema/user'
import { trpc } from '@/trpc/client'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { FormEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

interface Props {}

export default function LoginForm({}: Props) {
    const { t } = useTranslation()
    const { push } = useRouter()
    const {
        mutate: loginM,
        isLoading,
        error,
    } = trpc.login.useMutation({
        onSuccess: async ({ token, app }) => {
            toast.success(t('login.success'))
            const newUrl = `/${app.toLowerCase()}/`
            await postLogin(newUrl)
            push(newUrl)
        },
        onError: () => {
            toast.error(t('login.fail'))
        },
    })

    const handleSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault()
            const fd = new FormData(e.target as HTMLFormElement)
            const [name, password] = [
                fd.get('username')?.toString(),
                fd.get('password')?.toString(),
            ]

            if (!name || !password) {
                return
            }

            loginM({
                name,
                password,
            })
        },
        [loginM]
    )

    return (
        <div
            key="1"
            className="w-full h-full flex flex-col items-center justify-start bg-white mx-auto"
        >
            <div className="mt-52 text-center min-w-[35vw]">
                <h1 className="text-black font-bold text-7xl">
                    we
                    <span className="text-blue-3">pro.</span>
                </h1>
            </div>
            <div className="mt-24 min-w-[35vw]">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-blue-dark-1">
                    {t('login.text')}
                </h2>
                <form className="mt-8" onSubmit={handleSubmit}>
                    <div className="rounded-md shadow-sm">
                        <div className="mb-4">
                            <label className="sr-only" htmlFor="email-address">
                                {t('login.field.email')}
                            </label>
                            <input
                                className="relative block w-full appearance-none rounded-lg border border-gray-200 px-3 py-4 placeholder-blue-light-1 text-gray-900 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 dark:border-blue-3"
                                id="username"
                                name="username"
                                placeholder={t('login.field.email')}
                                required
                                type="text"
                            />
                        </div>
                        <div className="mb-8">
                            <label className="sr-only" htmlFor="password">
                                {t('login.field.password')}
                            </label>
                            <input
                                autoComplete="current-password"
                                className=" block w-full appearance-none rounded-lg border border-gray-200 px-3 py-4 placeholder-blue-light-1 text-gray-900 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm: dark:border-blue-3"
                                id="password"
                                name="password"
                                placeholder={t('login.field.password')}
                                required
                                type="password"
                            />
                        </div>
                    </div>
                    <div className="mb-4">
                        <button
                            disabled={isLoading}
                            className="w-full justify-center rounded-xl border border-yellow-1 bg-yellow-1 py-4 px-4  font-medium text-white hover:opacity-85"
                            type="submit"
                        >
                            {t('login.login_btn')}
                        </button>
                    </div>
                    {error && (
                        <div className="text-red-800 text-center mb-4">
                            * {t('login.fail.long')}
                        </div>
                    )}
                    <div className="mt-20 text-center">
                        <Link href="/forgotten-password">
                            {t('login.forgottenPasswordLink')}
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    )
}
